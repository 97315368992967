<template>
  <div>
      <div v-if="seeTable">
        <transition name="slide-fade">
          <ensayos :setSee="setSee"/>
        </transition>
      </div>
      <div v-else>
        <transition name="slide-fade">
          <v-card>
            <v-toolbar
              color="grey lighten-4"
              height="100px"
               class="c-view-title"
              >
            <!--<toogleNavMenu ref="toogleNavMenu" />-->
            <div class="c-view-title-text col-lg-4 col-sm-4 col-4">
                <v-toolbar-title>
                  <!-- <v-form ref="formNameEnsayo"> -->
                    <v-text-field
                      label="Nombre del ensayo"
                      class="c-view-title__input inline-block"
                      v-model="form_data.data_ensayo.nombre"
                      :rules="rules.name"
                      required
                    ></v-text-field>
                    <v-chip
                    x-small
                    label
                    color="green darken-1"
                    class="c-tag c-tag--active"
                    dark
                    >
                      Active
                    </v-chip>
                    <v-checkbox
                      v-model="form_data.steriplex"
                      class="inline-block"
                      label="Steriplex"
                      @change="selectedTipores($event)"
                    ></v-checkbox>
                  <!-- </v-form> -->
                </v-toolbar-title>
            </div>
                <v-spacer></v-spacer>
                <v-chip
                  class="ma-2 btn-secondary c-btn c-btn--secondary"
                  color="indigo darken-3"
                  outlined
                  @click="cancelForm()"
                >
                  <!--<v-icon left>mdi-fire</v-icon>-->
                  <span class="entities mr-2">&times;</span>
                  Cancelar
                </v-chip>
                <v-chip
                  class="ma-2 c-btn c-btn--primary"
                  color="indigo darken-3"
                  :disabled="isSaveDisabled"
                  @click="step3()"
                  outlined
                >
                  <!--<v-icon left>mdi-fire</v-icon>-->
                  <span class="entities mr-2">&check;</span>
                  Guardar
                </v-chip>
            </v-toolbar>
            <v-card-text>
              <v-stepper v-model="step" vertical >
                  <!-- PASO 1 -->
                  <v-stepper-step :complete="form_data.step > 1" step="1">
                      Algunos datos básicos sobre tu nuevo ensayo
                      <!-- <small> </small> -->
                  </v-stepper-step>

                  <v-stepper-content step="1">
                      <formInformation ref="formInfoEns"></formInformation>
                      <v-btn color="primary"
                      class="c-btn c-btn--primary"
                      @click="step1()">Continuar</v-btn>
                  </v-stepper-content>
                  <!-- FIN PASO 1 -->

                  <!-- PASO 2 -->
                  <v-stepper-step :complete="form_data.step > 2" step="2">
                      Detalle de la técnica
                      <!-- <small> Agrega o remueve matrices </small> -->
                  </v-stepper-step>

                  <v-stepper-content step="2">
                      <formDetalle ref="formInfoDetalle"></formDetalle>

                      <v-btn color="primary"
                      class="c-btn c-btn--primary"
                      @click="step2()">Continuar</v-btn>
                      <v-btn text
                       class="c-btn c-btn--secondary"
                       @click="setStep(1)">Atrás</v-btn>
                  </v-stepper-content>
                  <!-- FIN PASO 2 -->

                  <!-- PASO 3 -->
                  <v-stepper-step :complete="form_data.step > 3" step="3">
                      Matrices y umbrales
                      <!-- <small> Agrega o desactiva patogenos de este ensayo </small> -->
                  </v-stepper-step>

                  <v-stepper-content step="3">
                      <formUmbrales ref="formInfoUmbrales"></formUmbrales>

                      <v-btn
                        color="primary"
                        class="c-btn c-btn--primary"
                        @click="step3()"
                        :loading="loadingF"
                        :disabled="loadingF">
                        Guardar
                      </v-btn>
                      <v-btn
                       class="c-btn c-btn--secondary"
                       text @click="setStep(2)">Atrás</v-btn>
                  </v-stepper-content>
                  <!-- FIN PASO 3 -->

                  <!-- OVERLAY -->
                  <v-overlay z-index="7" :value="overlay">
                      <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>

              </v-stepper>
            </v-card-text>
          </v-card>
        </transition>
      </div>

        <v-snackbar
        v-model="error.error"
        :timeout="2000"
        >
          {{ error.txt }}
          <v-btn
              color="red"
              text
              @click="error.error = false"
          >
              Cerrar
          </v-btn>
        </v-snackbar>

        <v-snackbar
        v-model="snack.activo"
        :timeout="2000"
        :top="true"
        >
          {{ snack.txt }}
          <v-btn
              :color="snack.color"
              text
              @click="snack.activo = false"
          >
            Cerrar
          </v-btn>
        </v-snackbar>

        <!-- dialod -->
    <v-dialog v-model="dialogensayo" max-width="500" persistent>
        <v-card>
            <v-card-title class="headline">
                Ensayo Duplicado
            </v-card-title>

            <v-card-text>
                Existe algunos ensayos con las mismas caracteristicas favor de revisar
                o crear el ensayo de todas formas.

                <v-list>
                  <v-list-group
                    v-for="item in ensayoduplicados"
                    :key="item.id"
                    prepend-icon="mdi-ticket"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.ensa_informe"></v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.metodologia"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </v-list>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="aceptarModal">
                    Aceptar
                </v-btn>

                <v-btn color="green darken-1" text @click="crearensayo">
                    Crear Ensayo nuevo
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Service from '../services/apis';

export default {
  name: 'Ensayos',
  components: {
    ensayos: () => import('./Ensayos/Ensayos'),
    formInformation: () => import('./Ensayos/Informacion'),
    formDetalle: () => import('./Ensayos/Detalle'),
    formUmbrales: () => import('./Ensayos/Umbrales'),
    // toogleNavMenu: () => import('./toogleNavMenu'),
  },
  computed: {
    ...mapState('Ensayos', ['data_ensayo', 'bool_form_steps', 'form_data', 'matrizunidad', 'userInfo']),
    step() {
      return this.form_data.step;
    },
    isSaveDisabled() {
      return (this.form_data.step !== 3);
      // return true;
    },
  },
  data() {
    return {
      dialogensayo: false,
      ensayoduplicados: [],
      steriplex: false,
      seeTable: true,
      loadingF: false,
      show: false,
      error: {
        error: false,
        txt: null,
      },
      snack: {
        activo: false, txt: null, color: 'red', error: false,
      },
      response: null,
      selected: [],
      overlay: false,
      rules: {
        name: [
          (v) => !!v || 'El nombre del ensayo es requerido',
        ],
      },
    };
  },
  methods: {
    ...mapMutations('Ensayos', ['setValidDataEnsayo', 'setFormStep', 'setStep', 'clearAllDataForm', 'datosEnsayo', 'datosParametrossel', 'fillObjectForm', 'datosUser']),
    step1() {
      this.setValidDataEnsayo(this.$refs.formInfoEns.validate());
      if (this.form_data.data_ensayo_valid) {
        this.setStep(2);
      }
    },
    step2() {
      // obtenemos lo parametros seleccionados para asignarles su nivel de peligrosidad
      const parametrosSelected = [];
      this.data_ensayo.parametros.forEach((element) => {
        this.form_data.data_ensayo.parametrosid.forEach((element2) => {
          if (element.cod_pat === element2) {
            /* eslint-disable no-param-reassign */
            element.peligrosidadID = 0;
            parametrosSelected.push(element);
          }
        });
      });
      // filtro los que son editados para sacar su idpeligrosidad
      parametrosSelected.forEach((element) => {
        this.form_data.parametrosedit.forEach((element2) => {
          if (element.cod_pat === element2.patogeno_new_cod_pat) {
            element.peligrosidadID = element2.peligrosidadID;
          }
        });
      });
      this.datosParametrossel(parametrosSelected);
      // fin de obtencion de parametros
      if (this.$refs.formInfoDetalle.validate()) {
        /// validaciones
        let numberMatriz = 0;
        let unidadVacio = 0;
        this.form_data.matrices_unidades.forEach((element) => {
          this.matrizunidad.ali = 1;
          if (element.activo === true) {
            numberMatriz += 1;
          }
          if (element.activo === true && element.unidades === '') {
            unidadVacio += 1;
          }
        });
        if (numberMatriz === 0) {
          this.snack.error = true;
          this.snack.activo = true;
          this.snack.color = 'red';
          this.snack.txt = 'Selecciona una matriz con sus unidades';
          return;
        }
        if (unidadVacio > 0) {
          this.snack.error = true;
          this.snack.activo = true;
          this.snack.color = 'red';
          this.snack.txt = 'Agregar unidades a la matriz seleccionada';
          return;
        }
        this.setStep(3);
      }
    },
    async step3() {
      if (this.$refs.formInfoUmbrales.validate()) {
        this.loadingF = true;
        console.log(this.$store.state.Ensayos.userInfo);
        const formData = JSON.parse(JSON.stringify(this.form_data));
        formData.user = JSON.parse(JSON.stringify(this.$store.state.Ensayos.userInfo));
        /*
        const verifica = await Service.apiToken('POST', 'verifica-ensayo',
         this.$ls.storage.token, this.form_data);
        */
        /* eslint no-lonely-if: "error" */
        if (this.form_data.data_ensayo_original) {
          await Service.apiToken('POST', 'edit-ensayos', this.$ls.storage.token, formData);
          // regresamos a inicio
          this.loadingF = false;
          this.cancelForm();
        } else {
          // si la respuesta es mayo a 0 mostramos el modal con los ensayos posibles
          // if (verifica.results.length > 0) {
          //   this.ensayoduplicados = verifica.results;
          //   this.dialogensayo = true;
          //   return;
          // }
          /// si no se crea el ensayo normal
          await Service.apiToken('POST', 'save-ensayos', this.$ls.storage.token, formData);
          this.loadingF = false;
          this.cancelForm();
          // await Service.apiToken('POST', 'save-ensayos', this.$ls.storage.token, this.form_data);
        }

        this.loadingF = false;
        // regresamos a la lista de ensayos
        // this.cancelForm();
      }
    },
    async crearensayo() {
      await Service.apiToken('POST', 'save-ensayos', this.$ls.storage.token, this.form_data);
      this.dialogensayo = false;
      this.loadingF = false;
      this.cancelForm();
    },
    async finalizar() {
      const E = false; // error
      this.overlay = true;
      this.overlay = false;
      return E;
    },
    cancelForm() {
      this.clearDataForm();
      this.setSee(true);
    },
    clearDataForm() {
      this.form_data.data_ensayo.tipoEnsa = null;
      this.form_data.data_ensayo.tipoMeto = null;
      this.form_data.data_ensayo.empresa = null;
      this.form_data.data_ensayo.ens_tipo = null;
      this.form_data.data_ensayo.descripcion = null;
      this.form_data.data_ensayo.metodologia = null;
      this.form_data.data_ensayo.numplacas = null;
      this.form_data.data_ensayo.parametrosid = [];
      this.form_data.data_ensayo.tiempoenriquecimiento = null;
      this.form_data.data_ensayo.tiemporesult = null;
      this.form_data.data_ensayo.nombre = null;
      this.form_data.data_ensayo.tecnica = null;
      this.form_data.data_ensayo.productoid = null;
      this.form_data.data_ensayo.tipoRes = null;
      this.form_data.data_ensayo.sitioAnalisis = null;
      this.form_data.data_ensayo_original = null;
      this.form_data.data_ensayo.ensayoSelect = null;
      this.form_data.isNew = null;
      this.form_data.data_ensayo.mixes = null;
      this.form_data.nuevoensayo = false;
      this.form_data.parametrosedit = [];
      this.form_data.steriplex = false;

      const umbral = [{ id: 0, umbral: '', rsa_id: [] }];

      this.form_data.ambientesSelect = umbral;
      this.form_data.aguasSelect = umbral;
      this.form_data.manipuldoresSelect = umbral;
      this.form_data.superficiesSelect = umbral;
      this.form_data.alimentosSelect = umbral;

      this.form_data.matrices_unidades.map((currentValue, index) => {
        const curr = currentValue;
        this.form_data.matrices_unidades[index].activo = false;
        this.form_data.matrices_unidades[index].unidades = '';
        return curr;
      });

      this.setStep(1);
    },
    async setSee(value, testSelected = null, isNew = null) {
      this.clearDataForm();
      const testToEdit = (testSelected) ? await Service.apiToken('POST', 'get-test-id', this.$ls.storage.token, testSelected) : null;
      // console.log('testToEdit', testToEdit);
      if (testToEdit && testToEdit.data) {
        const dataTest = testToEdit.data[0];
        const empresaProducto = dataTest.ensayos_empresas_productos[0] || [];
        const laboratorio = dataTest.ensayos_laboratorios[0] || [];
        const mix = dataTest.ensayos_has_mixs[0] || [];
        const codesPat = dataTest.patogeno_new_has_ensayos.map((pat) => pat.patogeno_new.cod_pat);
        // si el ensayo no es del cliente logueado
        if (this.userInfo.user.id_planta !== dataTest.cliente_id) {
          // cambiamos a true para bloquear campos
          this.form_data.isComercial = true;
        }
        this.form_data.data_ensayo.cod_ensa = dataTest.cod_ensa;
        this.form_data.data_ensayo.tipoEnsa = dataTest.tipo_ensayo_id;
        this.form_data.data_ensayo.tipoMeto = (dataTest.comercial) ? 1 : 0;
        this.form_data.data_ensayo.empresa = empresaProducto.empresa_id || null;
        this.form_data.data_ensayo.ens_tipo = dataTest.d_tipo_de_ensayo;
        // this.form_data.data_ensayo.descripcion = dataTest.ensa_desc;
        this.form_data.data_ensayo.descripcion = dataTest.ensayos_easypcrs[0].descripcion;
        this.form_data.data_ensayo.metodologia = dataTest.metodologia;
        this.form_data.data_ensayo.numplacas = dataTest.numero_placas;
        this.form_data.data_ensayo.parametrosid = codesPat;
        // this.form_data.data_ensayo.nombre = dataTest.ensa_informe;
        this.form_data.data_ensayo.nombre = dataTest.ensayos_easypcrs[0].name_ensayo;
        this.form_data.data_ensayo.tiempoenriquecimiento = dataTest.tiempo_incubacion;
        this.form_data.data_ensayo.tiemporesult = dataTest.dias;
        this.form_data.data_ensayo.tecnica = dataTest.tecnicas_id;
        this.form_data.data_ensayo.productoid = empresaProducto.producto_empresa_id || null;
        this.form_data.data_ensayo.tipoRes = dataTest.t_resultado;
        this.form_data.data_ensayo.sitioAnalisis = laboratorio.laboratorio_id || null;
        this.form_data.data_ensayo_original = dataTest.grupo_ensayo_id;
        this.form_data.data_ensayo.mixes = mix.mixs_id || null;
        this.form_data.nuevoensayo = true;
        // recorremos patogeno new has ensayo
        dataTest.patogeno_new_has_ensayos.forEach((element) => {
          element.nombre = element.patogeno_new.nombre;
          element.peligrosidadID = element.id_nivel_peligro;
        });
        this.form_data.parametrosedit = dataTest.patogeno_new_has_ensayos;
        // asignamos si es un ensayo steriplex o no
        this.form_data.steriplex = (dataTest.steriplex === 1);
        this.form_data.kitsteriplex = dataTest.kit_steriplex;
        this.form_data.clasificacion = dataTest.clasificacion;
        console.log('data', testToEdit.data);
        testToEdit.data.forEach((test) => {
          const matrixObject = this.form_data.matrices_unidades;
          // console.log(matrixObject);
          const matrixFound = matrixObject.findIndex((element) => element.id === test.matrices_id);
          this.form_data.matrices_unidades[matrixFound].activo = true;
          this.form_data.matrices_unidades[matrixFound].unidades = test.unidades;
          let umbral = [];
          const arrayMatriz = [12, 9, 7];
          let contindex = 0;
          // const groups = _.groupBy(test.umbrales, 'umbral');
          console.log('test umbrales', test.umbrales);
          const groups = _.groupBy(test.umbrales, 'unidades');
          /// /agregamos nuevo
          Object.keys(groups).map((group) => {
            const agrupaumbral = _.groupBy(groups[group], 'umbral');
            // console.log(index, group);
            // console.log(agrupaumbral);
            // recorre agrupado 2
            Object.keys(agrupaumbral).map((groupumbral) => {
              if (arrayMatriz.includes(groups[group][0].matriz_id)) {
                umbral.push({
                  id: contindex,
                  umbral: agrupaumbral[groupumbral][0].umbral,
                  unidades: agrupaumbral[groupumbral][0].unidades,
                  rsa_id: agrupaumbral[groupumbral].map((umb) => (umb.subrsa_id === 0 ? `${umb.rsa_id}_R`
                    : `${umb.subrsa_id}_S_${umb.rsa_id}`)),
                });
              } else {
                umbral.push({
                  id: contindex,
                  umbral: agrupaumbral[groupumbral][0].umbral,
                  rsa_id: agrupaumbral[groupumbral].map((umb) => umb.rsa_id),
                  unidades: agrupaumbral[groupumbral][0].unidades,
                });
              }
              contindex += 1;
              return true;
            });
            return true;
          });
          // console.log('umbrales', umbral);
          /// fin de nuevo
          /*
          console.log('agreoado0', groups);
          let umbral = [];
          const arrayMatriz = [12, 9, 7];
          Object.keys(groups).map((group, index) => {
            // if (groups[group][0].matriz_id === 12) {
            if (arrayMatriz.includes(groups[group][0].matriz_id)) {
              umbral.push({
                id: index,
                umbral: groups[group][0].umbral,
                unidades: groups[group][0].unidades,
                rsa_id: groups[group].map((umb) => (umb.subrsa_id === 0 ? `${umb.rsa_id}_R`
                  : `${umb.subrsa_id}_S_${umb.rsa_id}`)),
              });
            } else {
              umbral.push({
                id: index,
                umbral: groups[group][0].umbral,
                rsa_id: groups[group].map((umb) => umb.rsa_id),
                unidades: groups[group][0].unidades,
              });
            }

            return true;
          }); */
          const noUmbral = [{ id: 0, umbral: '', rsa_id: [] }];
          umbral = (umbral.length > 0) ? umbral : noUmbral;

          switch (test.matrices_id) {
            case 6: this.form_data.data_umbrales.ambientesSelect = umbral; break;
            case 7: this.form_data.data_umbrales.aguasSelect = umbral; break;
            case 8: this.form_data.data_umbrales.manipuldoresSelect = umbral; break;
            case 9: this.form_data.data_umbrales.superficiesSelect = umbral; break;
            case 12: this.form_data.data_umbrales.alimentosSelect = umbral; break;
            default: break;
          }
          return true;
        });
      }
      // console.log('matricesunidades', this.form_data.matrices_unidades);
      this.form_data.isNew = isNew;
      this.seeTable = value;
    },
    selectedTipores(event) {
      if (event === true) {
        this.form_data.data_ensayo.tipoRes = 8;
      } else {
        this.form_data.kitsteriplex = 0;
        this.form_data.data_ensayo.tipoRes = null;
      }
    },
    aceptarModal() {
      this.dialogensayo = false;
    },
  },
  async created() {
    const data = await Service.apiToken('POST', 'ensayos', this.$ls.storage.token, { });
    this.datosEnsayo(data);
    const datosUser = await Service.apiToken('POST', 'get-userinfo', this.$ls.storage.token, { });
    this.datosUser(datosUser.data);
  },
};
</script>
